import React, { useEffect, useState } from 'react';
import { Button, Col } from 'react-bootstrap';
import TableBody from '@material-ui/core/TableBody';
import {
  Card, CardBody, CardTitleWrap, CardTitle,
} from '@/shared/components/Card';
import {
  Table, TableCheckbox, TableRow, TableWrap, TablePagination, TableCell,
} from '@/shared/components/MaterialTableElements';
import { Checkbox } from '@material-ui/core';
import {
  FormContainer, FormGroup, FormGroupField, FormGroupIcon,
} from '@/shared/components/form/FormElements';
import MagnifyIcon from 'mdi-react/MagnifyIcon';
import styled from 'styled-components';
import MatTableHead from './MatTableHead';
import MatTableToolbar from './MatTableToolbar';
import { getAllOrders } from '../../../utils/api/task';
import SingleOrder from './SingleOrder';



const getSorting = (order, orderBy) => {
  if (order === 'desc') {
    return (a, b) => {
      if (a[orderBy] < b[orderBy]) {
        return -1;
      }
      if (a[orderBy] > b[orderBy]) {
        return 1;
      }
      return 0;
    };
  }
  return (a, b) => {
    if (a[orderBy] > b[orderBy]) {
      return -1;
    }
    if (a[orderBy] < b[orderBy]) {
      return 1;
    }
    return 0;
  };
};

const OrdersTable = ({ type, status }) => {
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('calories');
  const [selected, setSelected] = useState(new Map([]));
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [data, setData] = useState([
  ]);
  const [dataCount, setDataCount] = useState(0);
  const [searchText, setSearchText] = useState('');


  const handleRequestSort = (event, property) => {
    const orderByTemp = property;
    let orderTemp = 'desc';
    if (orderBy === property && order === 'desc') { orderTemp = 'asc'; }
    setOrder(orderTemp);
    setOrderBy(orderByTemp);
  };

  const handleSelectAllClick = (event, checked) => {
    if (checked) {
      const newSelected = new Map();
      data.map(n => newSelected.set(n.id, true));
      setSelected(newSelected);
      return;
    }
    setSelected(new Map([]));
  };

  const handleClick = (event, id) => {

  };

  const handleChangePage = (event, item) => {
    setPage(item);
    getAllOrders({
      status, type, pageSize: rowsPerPage, page: item + 1, search: searchText,
    }).then((res) => {
      setData(res?.data?.orders || []);
    });
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(Number(event.target.value));
    setPage(0);
  };

  const handleDeleteSelected = () => {
    let copyData = [...data];
    for (let i = 0; i < [...selected].filter(el => el[1]).length; i += 1) {
      copyData = copyData.filter(obj => obj.id !== selected[i]);
    }
    setData(copyData);
    setSelected(new Map([]));
  };

  const isSelected = id => !!selected.get(id);
  const emptyRows = rowsPerPage - Math.min(rowsPerPage, data.length - (page * rowsPerPage));


  const fetchOrders = () => {
    getAllOrders({
      status, type, rowsPerPage, page: page + 1, search: searchText,
    }).then((res) => {
      setData(res?.data?.orders || []);
      setDataCount(res?.data?.total);
    });
  };

  useEffect(() => {
    fetchOrders();
  }, []);


  return (
    <Col md={12} lg={12}>
      <Col xs={7} md={6} lg={3} className="p-2">
        <FormContainer onSubmit={(e) => {
          e.preventDefault();
          fetchOrders();
        }}
        >
          <FormGroup>
            <FormGroupField>
              <input name="search" type="text" placeholder="Поиск по номеру ТС или заказа" value={searchText} onChange={e => setSearchText(e.target.value)} />
              <FormGroupIcon>
                <SearchBtn onClick={fetchOrders} type="button">
                  <MagnifyIcon />
                </SearchBtn>
              </FormGroupIcon>
            </FormGroupField>

          </FormGroup>
        </FormContainer>
      </Col>
      {data.length > 0 && (
        <Card>
          <CardBody>
            <TableWrap>
              <Table>
                <MatTableHead
                  numSelected={[...selected].filter(el => el[1]).length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={data.length}
                />
                <TableBody>
                  {data
                    .map((item) => {
                      const select = isSelected(item.order_id);
                      return <SingleOrder item={item} handleClick={handleClick} select={select} />;
                    })}
                </TableBody>
              </Table>
            </TableWrap>
            <TablePagination
              component="div"
              count={dataCount}
              rowsPerPage={rowsPerPage}
              page={page}
              backIconButtonProps={{ 'aria-label': 'Previous Page' }}
              nextIconButtonProps={{ 'aria-label': 'Next Page' }}
              onChangePage={handleChangePage}
              onChangeRowsPerPage={handleChangeRowsPerPage}
              rowsPerPageOptions={[50]}
              dir="ltr"
              SelectProps={{
                inputProps: { 'aria-label': 'rows per page' },
                native: true,
              }}
            />
          </CardBody>
        </Card>
      )}

    </Col>
  );
};

export default OrdersTable;


const SearchBtn = styled('button')`
border:none`;
