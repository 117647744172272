import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { useHistory, useParams } from 'react-router';
import { FormButtonToolbar } from '@/shared/components/form/FormElements';
import {
  Card, CardBody, CardTitleWrap, CardTitle, CardSubhead,
} from '@/shared/components/Card';
import { Button } from '@/shared/components/Button';
import { Table } from '@/shared/components/TableElements';
import styled from 'styled-components';
import ArrowLeftIcon from 'mdi-react/ArrowLeftIcon';
import { Input } from '@material-ui/core';
import { SelectField } from '@/shared/components/form/Select';
import ModalConfirmation from '../../../shared/components/ModalConfirmation';
import {
  addClientToGroup, approveDiscount,
  changeTypePayment,
  getAvailableGroups,
  getOrderPreview,
  keysReturned,
  payOrder,
  requestDiscount,
  updateOrder,
} from '../../../utils/api/task';
import TasksTable from './components/TasksTable';
import Modal from '../../../shared/components/Modal';
import PaymentForm from './components/PaymentForm';
import Badge from '../../../shared/components/Badge';

export default function PreviewTask() {
  const { id } = useParams();
  const [order, setOrder] = useState(null);
  const [washerList, setWasherList] = useState([]);
  const [tireWorkersList, setTireWorkersList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [payClass, setPayClass] = useState('');
  const [jobClass, setJobClass] = useState('');
  const history = useHistory();
  const [discount, setDiscount] = useState(null);
  const [comment, setComment] = useState('');
  const [commentManually, setCommentManually] = useState(false);
  const [availableGroups, setAvailableGroups] = useState([]);
  
  function getPayClass(data) {
    let newPayClass = 'warning';
    if (data?.is_legal_client) {
      newPayClass = 'primary';
    } else if (data?.pay_status === 3) {
      newPayClass = 'success';
    } else if (data?.pay_status === 4) {
      newPayClass = 'success';
    }
    return newPayClass;
  }

  function getJobClass(data) {
    let newJobClass = 'warning';
    if (data?.job_status === 'in_progress') {
      newJobClass = 'primary';
    } else if (data?.job_status === 'done') {
      newJobClass = 'success';
    } else if (data?.job_status === 'canceled') {
      newJobClass = 'outline-secondary';
    }
    return newJobClass;
  }

  function getPaymentMethodText(method, fuelCardName) {
    let text = 'Наличные';
    let card = fuelCardName;
    if (method === 'card') {
      text = 'Банковская карта';
    } else if (method === 'fuel') {
      if (fuelCardName === 'yandex') {
        card = 'Яндекс';
      } else if (fuelCardName === 'petrol') {
        card = 'Петрол+';
      }
      text = `Топливная карта ${card}`;
    }
    return text;
  }

  const changeDiscount = async (value) => {
    await updateOrder({
      discount: value,
      orderId: order.order_id,
    });
    const { data } = await getOrderPreview(id);
    setOrder(data);
    const newPayClass = getPayClass(data);
    setPayClass(newPayClass);
    const newJobClass = getJobClass(data);
    setJobClass(newJobClass);
    setDiscount(value);
  };

  const handleAddGroup = (group) => {
    addClientToGroup(order.client.id, group.id).then(async () => {
      const res = await getOrderPreview(id);
      setOrder(res.data);
    });
  };

  const updateComment = async (value) => {
    await updateOrder({
      comment: value,
      orderId: order.order_id,
    });
    const { data } = await getOrderPreview(id);
    setOrder(data);
    const newPayClass = getPayClass(data);
    setPayClass(newPayClass);
    const newJobClass = getJobClass(data);
    setJobClass(newJobClass);
  };


  useEffect(() => {
    if (id) {
      getOrderPreview(id).then((res) => {
        setOrder(res.data);
        setDiscount(res?.data?.discount);
        const newPayClass = getPayClass(res.data);
        setPayClass(newPayClass);
        if (res?.data?.is_first_order && !res?.data?.discount && !res?.data?.comment) {
          setDiscount(30);
          setComment('Первый раз');
        }
        if (res?.data?.client?.loyalty_discount) {
          setDiscount(res?.data?.client?.loyalty_discount);
        }
        const newJobClass = getJobClass(res.data);
        setJobClass(newJobClass);
      });
      getAvailableGroups(id).then((res) => {
        if (!res.details) {
          setAvailableGroups(res.data);
        } else {
          setAvailableGroups([]);
        }
      });
    }

    return () => {
      setOrder(null);
    };
  }, []);


  const handlePayOrder = (data) => {
    setLoading(true);
    payOrder(order.order_id, data?.sum, data?.method, data?.fuel_card_name).then((response) => {
      if (response.result === 'ok') {
        // showNotification(
        //   { className: 'light' },
        //   { direction: 'ltr' },
        //   'Success',
        //   response.details,
        //   'success',
        // );
      }
      getOrderPreview(id).then((res) => {
        setOrder(res.data);
        setLoading(false);
        const newPayClass = getPayClass(res.data);
        setPayClass(newPayClass);

        const newJobClass = getJobClass(res.data);
        setJobClass(newJobClass);
      });
    });
  };

  /* eslint-disable no-param-reassign */
  const handleChangeTypePayment = (data) => {
    changeTypePayment(data);
    order.pay_transactions.forEach((el) => {
      if (el.id === data?.paymentId) {
        el.method = data.method;
        el.fuel_card_name = data.fuelCardName;
      }
    });
    setOrder({ ...order });
  };
  
  const [smsCode, setSmsCode] = useState(undefined);

  function InputCodeComponent() {
    return (
      <div>
        <Input value={smsCode} onChange={e => setSmsCode(e.target.value)} autoFocus />
      </div>
    );
  }
  
  async function requestSales(onSuccess) {
    const result = await requestDiscount({
      order_id: order.order_id,
      discount_amount: `${discount}%`,
      order_sum: order.total_sum,
    });
    
    if (result.result === 'ok') {
      onSuccess?.();
    }
  }
  
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const [isTimerCompleted, setIsTimerCompleted] = useState(false);
  const TIMER_DURATION = 60000; // 60 секунд

  function startTimer() {
    setIsTimerRunning(true);
    setIsTimerCompleted(false);
  }
  
  useEffect(() => {
    let timer;
    if (isTimerRunning) {
      timer = setTimeout(() => {
        setIsTimerCompleted(true);
        setIsTimerRunning(false);
      }, TIMER_DURATION);
    }
    return () => clearTimeout(timer);
  }, [isTimerRunning]);
  
  return (
    <>
      <BackButtonWrapper>
        <Col xs={5}>
          <Button onClick={history.goBack} variant="outline-secondary"><ArrowLeftIcon /><span>К списку заказов</span></Button>
        </Col>
      </BackButtonWrapper>
      {order && (
        <div>
          <Row>
            <Col xs={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>
                    <InfoBlock>Заказ №: {order.order_id}</InfoBlock>
                    <InfoBlock>Время создания: {order.datetime}</InfoBlock>
                    <InfoBlock>Машина:
                      <Badge bg="primary">
                        {order.car.plate_number}
                      </Badge>
                      / {order.car.model_text}
                    </InfoBlock>
                    {order.car.discount_group && (
                      <InfoBlock>Группа: {order.car.discount_group}</InfoBlock>
                    )}
                    <InfoBlock>Телефон: <a href={`tel:${order.client.phone}`}> {order.client.phone}</a></InfoBlock>
                    <InfoBlock>Статус: <Badge bg={jobClass}>{order.job_status_text}</Badge> {order?.done_at !== undefined && order.done_at !== null ? `/ ${order.done_at}` : ''}</InfoBlock>
                    <InfoBlock>Статус оплаты: <Badge bg={payClass}>{order.pay_status_text}</Badge></InfoBlock>
                    <InfoBlock>Ключи: {order.car.keys}
                      {order.car.keys.includes('Ключи в ячейке') && (order?.pay_status === 3 || order?.pay_status === 4) && (order.job_status === 'done' || order.job_status === 'canceled') && (
                        <KeysWrapper
                          onClick={async () => {
                            await keysReturned(order.order_id);
                            getOrderPreview(id).then(res => setOrder(res.data));
                          }}
                          variant="outline-secondary"
                          size="sm"
                        ><span>Вернуть ключи</span>
                        </KeysWrapper>
                      )}
                    </InfoBlock>
                    {order.master_name && (
                      <InfoBlock>Мастер: {order.master_name}</InfoBlock>
                    )}
                    {order?.workersForCashier !== undefined && order?.workersForCashier?.length !== 0 ? <InfoBlockWorkers>Исполнители: {order?.workersForCashier?.map(worker => worker).join(', ')}</InfoBlockWorkers> : ''}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
            <Col xs={12} md={6} lg={6} xl={6}>
              <Card>
                <CardBody>
                  <CardTitle>
                    <InfoBlock>Итого по заказу: {order.total_sum} ₽</InfoBlock>
                    <InfoBlock>Скидка на заказ: {order.discount_sum} ₽</InfoBlock>
                    <InfoBlock>Итого по заказу с учётом всех скидок: {order.sum_final} ₽</InfoBlock>
                    <InfoBlock>Уже оплачено: {order.total_paid} ₽</InfoBlock>
                    <InfoBlock>К оплате: {order.pay_sum} ₽</InfoBlock>
                    {order?.pay_at !== undefined && order?.pay_at !== null ? <InfoBlock>Время полной оплаты: {order.pay_at}</InfoBlock> : null}
                  </CardTitle>
                </CardBody>
              </Card>
            </Col>
          </Row>
          <Row>
            {order.pay_status !== 3 && order.pay_status !== 4 && (
              <Col xs={6}>
                <Card>
                  <CardBody>
                    <CardTitleWrap>
                      <Title>Скидка</Title>
                    </CardTitleWrap>
                    <FormButtonToolbar>
                      {/* <Button variant="primary" type="button" onClick={() => changeDiscount(0)} disabled={!discount}>Нет</Button> */}
                      <Button variant="primary" type="button" onClick={() => changeDiscount(5)} disabled={discount === 5}>5%</Button>
                      <Button variant="primary" type="button" onClick={() => changeDiscount(10)} disabled={discount === 10}>10%</Button>
                      <Button variant="primary" type="button" onClick={() => changeDiscount(15)} disabled={discount === 15}>15%</Button>
                      <Button variant="primary" type="button" onClick={() => changeDiscount(20)} disabled={discount === 20}>20%</Button>
                    </FormButtonToolbar>
                    <FormButtonToolbar>
                      <PercentInput
                        onChange={(e) => {
                          console.log(e.target.value);
                          if (e.target.value <= 100 && e.target.value >= 0) {
                            setDiscount(e.target.value);
                          }
                        }}
                        type="number"
                        value={discount}
                      /> <Percent>%</Percent>

                    </FormButtonToolbar>
                    <ModalConfirmation
                      color="primary"
                      title=""
                      btn="primary"
                      btnComponent={(toggle) => {
                        if (order?.discount) {
                          return (
                            <>
                              <Button
                                variant="primary"
                                onClick={() => requestSales(async () => {
                                  toggle();
                                  startTimer();
                                })}
                                disabled={isTimerRunning && !isTimerCompleted}
                                size="sm"
                              >
                                {order?.client?.loyalty_discount || order?.discount || (isTimerCompleted || isTimerRunning) ? 'Запросить скидку повторно' : 'Запросить скидку'}
                              </Button>
                              <Button
                                variant="outline-secondary"
                                onClick={() => changeDiscount(0)}
                                size="sm"
                              >Отменить
                              </Button>
                            </>
                          );
                        }

                        return (
                          <Button
                            variant="primary"
                            onClick={() => requestSales(async () => {
                              toggle();
                              startTimer();
                            })}
                            disabled={isTimerRunning && !isTimerCompleted}
                            size="sm"
                          >
                            {order?.client?.loyalty_discount || order?.discount || (isTimerCompleted || isTimerRunning) ? 'Запросить скидку повторно' : 'Запросить скидку'}
                          </Button>
                        );
                      }}
                      closeOnError={false}
                      Body={<InputCodeComponent />}
                      message={`Введите код, чтобы подтвердить скидку ${discount}% для заказа  ${order.order_id}?`}
                      onSuccess={async (close) => {
                        if (smsCode === undefined || smsCode === '' || discount === undefined) return;
                        const result = await approveDiscount({ order_id: order.order_id, sms_code: smsCode, discount_amount: `${discount}%` });
                        
                        if (result.details === 'Скидка подтверждена и применена') {
                          close?.();
                          setDiscount(discount);
                          history.go(0); // update page
                        }
                        
                        if (result.details === 'Код не совпадает') {
                          setDiscount(0);
                        }
                        setSmsCode(undefined);
                      }}
                    />
                    {' '}
                    {availableGroups.length > 0 && (
                      <CardTitle>Добавить клиента в дисконт-группу: </CardTitle>
                    )}
                    {availableGroups.map(group => (
                      <ButtonDiv>
                        <Modal
                          color="primary"
                          title=""
                          btn="primary"
                          btnComponent={toggle => (
                            <Button
                              variant="primary"
                              onClick={toggle}
                              size="sm"
                              disabled={order.discount}
                            >{group.name}
                            </Button>
                          )}
                          message={`Вы уверены, что хотите добавить клиента в дисконтную группу &laquo;${group.name}&raquo;?`}
                          onSuccess={() => handleAddGroup(group)}

                        />
                      </ButtonDiv>
                    ))}
                  </CardBody>
                </Card>
              </Col>
            )}
            <Col xs={6}>
              <Card>
                <CardBody>
                  <CardTitleWrap>
                    <Title>Основания скидки</Title>
                  </CardTitleWrap>
                  <FormButtonToolbar>
                    {!order.comment && (
                      <SelectField
                        onChange={(selectedOption) => {
                          if (selectedOption.value === 'Другое') {
                            setCommentManually(true);
                            if (order.comment) {
                              setComment(order.comment);
                            }
                            if (order.is_first_order && !order.discount && !order.comment) {
                              setComment('Первый раз');
                            }
                          } else {
                            setCommentManually(false);
                            setComment(selectedOption.value);
                          }
                        }}
                        placeholder={order?.client?.loyalty_discount ? `Скидка в этом месяце ${order?.client?.loyalty_discount} %` : undefined}
                        options={[{ value: 'ЖК Беговая 12', label: 'ЖК Беговая 12' }, { value: 'ЖК Беговая 16', label: 'ЖК Беговая 16' }, { value: 'ЖК Династия', label: 'ЖК Династия' }, { value: 'Porche Клуб', label: 'Porche Клуб' },
                        { value: 'ТЦ', label: 'ТЦ' }, { value: 'Другое', label: 'Другое' }]}
                      />
                    )}
                    {(commentManually || order.comment) && (
                      <CommentInput
                        multiline
                        value={comment}
                        // eslint-disable-next-line consistent-return
                        onChange={(e) => {
                          setComment(e.target.value);
                        }}
                      />
                    )}

                  </FormButtonToolbar>
                  {order?.discount && (
                    <Modal
                      color="primary"
                      title=""
                      btn="primary"
                      btnComponent={toggle => (
                        <Button
                          variant="primary"
                          onClick={() => toggle()}
                          size="sm"
                          disabled={comment === order.comment}
                        >Сохранить
                        </Button>
                      )}
                      message={`Вы уверены, что хотите сохранить комментарии для заказа  ${order.order_id}?`}
                      onSuccess={() => updateComment(comment)}
                    />
                  )}
                </CardBody>
              </Card>
            </Col>
          </Row>
          {
            (order.pay_status !== 3 && !order.is_pay_by_contract && order.pay_sum !== 0) && (
              <Card>
                <CardBody>
                  <CardTitleWrap>
                    <Title>Оплатить</Title>
                  </CardTitleWrap>
                  <PaymentForm onSubmit={handlePayOrder} isLoading={loading} defaultValue={order.pay_sum} />

                </CardBody>
              </Card>
            )
          }

          <TasksTable
            services={order.items}
            washerList={washerList}
            tierList={tireWorkersList}
          />
          {!order.is_pay_by_contract && (
            <>
              {order.pay_transactions?.length > 0 && (
                <Card>
                  <CardBody>
                    <Title>
                      История платежей

                    </Title>
                    <Table bordered responsive>
                      <thead>
                        <tr>
                          {[
                            { id: 1, title: 'Дата/время' },
                            { id: 2, title: 'Способ оплаты' },
                            { id: 3, title: 'Сумма' },
                          ].map(item => (
                            <th key={item.id}><InfoBlock>{item.title}</InfoBlock></th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {order.pay_transactions.map((item => (
                          <tr key={item.added_at}>
                            <td> <InfoBlock>{item.added_at}</InfoBlock></td>
                            <td><InfoBlock>{getPaymentMethodText(item.method, item.fuel_card_name)}</InfoBlock></td>
                            <td><InfoBlock>{item.sum}</InfoBlock></td>
                            <td>
                              {item.method !== 'cash'
                                  && (
                                  <ButtonDiv>
                                    <Modal
                                      color="primary"
                                      title=""
                                      btn="primary"
                                      btnComponent={toggle => (
                                        <Button onClick={() => toggle()} variant="primary" size="sm">Заменить на наличные
                                        </Button>
                                      )}
                                      message="Вы уверены, что хотите перевести оплату на наличные?"
                                      onSuccess={() => handleChangeTypePayment({ paymentId: item.id, method: 'cash' })}
                                    />
                                  </ButtonDiv>
                                  )}
                              {item.method !== 'card'
                                  && (
                                  <ButtonDiv>
                                    <Modal
                                      color="primary"
                                      title=""
                                      btn="primary"
                                      btnComponent={toggle => (
                                        <Button onClick={() => toggle()} variant="primary" size="sm">Заменить на банковскую карту</Button>
                                      )}
                                      message="Вы уверены, что хотите перевести оплату на банковскую карту?"
                                      onSuccess={() => handleChangeTypePayment({ paymentId: item.id, method: 'card' })}
                                    />
                                  </ButtonDiv>
                                  )}
                              {(item.method !== 'fuel' || (item.method === 'fuel' && item.fuel_card_name !== 'yandex'))
                                  && (
                                  <ButtonDiv>
                                    <Modal
                                      color="primary"
                                      title=""
                                      btn="primary"
                                      btnComponent={toggle => (
                                        <Button onClick={() => toggle()} variant="primary" size="sm">Заменить на топливную карту Яндекса</Button>
                                      )}
                                      message="Вы уверены, что хотите перевести оплату на топливную карту Яндекса?"
                                      onSuccess={() => handleChangeTypePayment({ paymentId: item.id, method: 'fuel', fuelCardName: 'yandex' })}
                                    />
                                  </ButtonDiv>
                                  )}
                              {(item.method !== 'fuel' || (item.method === 'fuel' && item.fuel_card_name !== 'petrol'))
                                  && (
                                  <ButtonDiv>
                                    <Modal
                                      color="primary"
                                      title=""
                                      btn="primary"
                                      btnComponent={toggle => (
                                        <Button onClick={() => toggle()} variant="primary" size="sm">Заменить на топливную карту Петрол+</Button>
                                      )}
                                      message="Вы уверены, что хотите перевести оплату на топливную карту Петрол+?"
                                      onSuccess={() => handleChangeTypePayment({ paymentId: item.id, method: 'fuel', fuelCardName: 'petrol' })}
                                    />
                                  </ButtonDiv>
                                  )}
                            </td>
                          </tr>

                        )))}

                      </tbody>
                    </Table>

                  </CardBody>
                </Card>
              )}
            </>
          )}
        </div>
      )}

    </>
  );
}

const BackButtonWrapper = styled.div`
  padding-left: 20px;
`;

const KeysWrapper = styled(Button)`
  margin: 0;
  margin-left: 20px;
`;

const InfoBlock = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
`;

const InfoBlockWorkers = styled.div`
  font-size: 18px;
  margin-bottom: 10px;
  line-height: 26px;
`;

const Title = styled(CardTitle)`
  font-size: 20px;
`;

const Percent = styled.div`
  font-size: 20px;
`;

const PercentInput = styled(Input)`
.MuiInputBase-input{

  text-align: end !important;
}
margin-right: 5px ;
`;

const CommentInput = styled(Input)`
width: 300px;
`;

const ButtonDiv = styled.div`
  display: inline;
  margin-right: 10px;
`;
