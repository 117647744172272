import { DEFAULT_ERROR_TEXT } from '../../../constants';
import api from '../base/axios';

export async function getCarInfoByPlate(plate) {
    try {
        const res = await api.post('new-order/check-plate', {
            plate_number: plate,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getCarsInfo() {
    try {
        const res = await api.get('get-cars-info');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getSuggestName(text) {
    try {
        const res = await api.get(`info/names?search=${text}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getSuggestBrand(text) {
    try {
        const res = await api.get(`info/translit-brands?search=${text}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getSuggestModel(text, brandId) {
    try {
        const res = await api.get(`info/translit-models?${text ? `search=${text}&` : ''}brand_id=${brandId}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getSuggestPlate(text) {
    try {
        const res = await api.get(`info/plates?search=${text}`);
        return res.data.data.plates;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function createNewTask(data) {
    try {
        const res = await api.post('new-order', data);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getWashServicesByClass(classId, orderId) {
    try {
        const res = await api.get(`service-list/wash?orderId=${orderId}&class_id=${classId}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getClientRecentOrders(plateNumber) {
    try {
        const res = await api.get(`get-client-recent-orders?plate_number=${plateNumber}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function addClientToGroup(orderId, groupId) {
    try {
        const res = await api.post('car/add-to-group', {
            order_id: orderId,
            group_id: groupId,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getRadiusOptions() {
    try {
        const res = await api.get('service-tire/radius');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getProfileOptions() {
    try {
        const res = await api.get('service-tire/profile');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getTiresServices(radiusId, profileId, orderId) {
    try {
        const res = await api.get(`service-list/tires?order_id=${orderId}&radius_id=${radiusId}&profile_id=${profileId}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line max-len
export async function updateOrder({
                                      action,
                                      serviceId,
                                      isWashService,
                                      quantity,
                                      orderId,
                                      phone,
                                      tireRadiusId,
                                      tireProfileId,
                                      comment,
                                      discount,
                                  }) {
    try {
        // eslint-disable-next-line max-len
        const res = await api.post('new-order/update', {
            action,
            service_id: serviceId,
            is_wash_service: isWashService,
            quantity,
            order_id: orderId,
            phone,
            tire_radius_id: tireRadiusId,
            tire_profile_id: tireProfileId,
            comment,
            discount,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getOrderPreview(orderId) {
    try {
        const res = await api.get(`order/${orderId}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getAvailableGroups(orderId) {
    try {
        const res = await api.get(`order/get-groups-available?order_id=${orderId}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function keysLeft(orderId) {
    try {
        const res = await api.post('order/keys-left', { order_id: orderId });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function keysTaken(orderId) {
    try {
        const res = await api.post('order/keys-taken', { order_id: orderId });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getWashWorkersList() {
    try {
        const res = await api.get('workers-wash/get');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function getTireWorkersList() {
    try {
        const res = await api.get('workers-tire/get');
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


// eslint-disable-next-line camelcase
export async function setWorker({
                                    // eslint-disable-next-line camelcase
                                    order_item_id, order_id, workers,
                                }) {
    try {
        const res = await api.post('order/set-worker', {
            order_item_id, order_id, workers,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function changeServiceStatus(order_item_id) {
    try {
        const res = await api.post('order/mark-item', { order_item_id });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function cancelService(order_item_id) {
    try {
        const res = await api.post('order/cancel-item', { order_item_id });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function cancelOrder(order_id) {
    try {
        const res = await api.post('order/cancel', { order_id });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function completeOrder(order_id) {
    try {
        const res = await api.post('order/complete', { order_id });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function startWork(order_id) {
    try {
        const res = await api.post('order/started', { order_id });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function getAllOrders({
                                       status, type, pageSize = 50, page = 1, search = '',
                                   }) {
    try {
        const res = await api.get(`order/list?status=${status}${type ? `&type=${type}` : ''}&page_size=${pageSize}&page=${page}&search_string=${search}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

export async function changeTypePayment({ paymentId, method, fuelCardName }) {
    try {
        const res = await api.post(`order/payment/switch-method?payment_id=${paymentId}&method=${method}&fuel_card_name=${fuelCardName}`);
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


// eslint-disable-next-line camelcase
export async function requestDiscount({ order_id, discount_amount, order_sum }) {
    try {
        const res = await api.post('order/request-discount', { order_id, discount_amount, order_sum });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function approveDiscount({ order_id, sms_code, discount_amount }) {
    try {
        const res = await api.post('order/approve-discount', { order_id, sms_code, discount_amount });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}

// eslint-disable-next-line camelcase
export async function payOrder(order_id, sum, method) {
    try {
        let fuelCardName = '';
        let methodPay = method;
        if (method.includes('fuel')) {
            const fuelWords = method.split('_');
            const secondWordIndex = 1;
            if (fuelWords.length > 1) {
                methodPay = 'fuel';
                fuelCardName = fuelWords[secondWordIndex];
            }
        }
        const res = await api.post('order/pay', {
            order_id, sum, method: methodPay, fuel_card_name: fuelCardName,
        });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}


export async function keysReturned(orderId) {
    try {
        const res = await api.post('order/keys-returned', { order_id: orderId });
        return res.data;
    } catch (error) {
        return { details: error?.response?.data?.details || DEFAULT_ERROR_TEXT };
    }
}
